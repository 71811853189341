<template>
  <!-- eslint-disable  -->
  <div>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
      <v-spacer></v-spacer>
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <SizeBox height="24" />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
        />
      </v-card-text>
      <DataTable
        :loading="isLoading"
        :list="result.list"
        :fields="headers"
        :labels="labels"
        :replace="['status', 'name1']"
        @get-list="fetchData"
      >
        <template #item.status="{ item }">
          <div class="flex-center">
            <v-switch
              color="Primary"
              :input-value="item.status"
              disabled
              inset
            ></v-switch>
          </div>
        </template>

        <template #item.name1="{ item }">
          <div>
            {{ item.bank_holder }}-{{
              item.bank_branch_name.replace(/銀行|银行/g, '')
            }}-{{ item.bank_last_digits }}
          </div>
        </template>
      </DataTable>
    </v-card>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>

<script>
import { getCollectChannels } from '@/api/collectChannels';
import { getCardSummary } from '@/api/merchantCenter/collectChannels';

export default {
  // components: { InfoForm, CollectRuleForm },
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      getCollectChannels,
      headers: [
        {
          text: '初始餘額',
          value: 'balance',
          align: 'center',
          width: '90px'
        },
        {
          text: '最低餘額限制',
          value: 'min_balance',
          align: 'center',
          width: '90px'
        },
        {
          text: '最大餘額限制',
          value: 'max_balance',
          align: 'center',
          width: '90px'
        },
        {
          text: '所屬通道',
          value: 'collect_channel.name',
          align: 'center',
          width: '120px'
        },
        {
          text: '異常狀態',
          value: 'abnormal_status',
          align: 'center',
          width: '120px'
        },

        { text: '名稱', value: 'name1', align: 'center', width: '180px' },
        { text: '帳戶狀態', value: 'status', align: 'center', width: '108px' },
        {
          text: '成功訂單筆數',
          value: 'success_order',
          align: 'center',
          width: '90px'
        },
        {
          text: '提交訂單筆數',
          value: 'total_order',
          align: 'center',
          width: '90px'
        },
        {
          text: '成功金額',
          value: 'success_amount',
          align: 'center',
          width: '64px'
        },
        {
          text: '提交金額',
          value: 'total_amount',
          align: 'center',
          width: '64px'
        },
        {
          text: '成功率(%)',
          value: 'success_rate',
          align: 'center',
          width: '78px'
        },

        { text: '輪詢權重', value: 'priority', align: 'center', width: '64px' },
        {
          text: '風控模式',
          value: 'collect_rule.mode',
          align: 'center',
          width: '110px'
        }
      ],
      labels: {
        abnormal_status: [
          { value: '', text: '正常', color: 'Success300' },
          { value: 'zero', text: '餘額為 0', color: '' },
          { value: 'frozen', text: '銀行停用', color: 'Error300' },
          { value: 'reach_max', text: '滿額', color: 'Error400' },
          { value: 'risk_control', text: '銀行風控', color: 'Alert500' },
          { value: 'judiciary', text: '銀行司法', color: 'Alert400' },
          { value: 'cancelled', text: '銀行註銷', color: 'Alert300' },
          { value: 'reach_min', text: '已達最低餘額限制', color: 'Error300' }
        ],
        'collect_rule.mode': [
          { value: 'inherit_channel', text: '繼承通道', color: 'Primary050' },
          { value: 'self_define', text: '自定義', color: 'Alert400' }
        ]
      },
      filterOptions: [
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'created_at',
          range: true,
          withTime: true
        },
        {
          label: '所屬通道',
          type: 'autocomplete',
          name: 'query.collect_channel_id',
          api: getCollectChannels
        },
        {
          label: '狀態',
          type: 'select',
          name: 'query.status',
          items: [
            { text: '開啟', value: true },
            { text: '關閉', value: false }
          ]
        },
        {
          label: '最小餘額',
          type: 'text',
          name: 'query.min_balance'
        },
        {
          label: '最大餘額',
          type: 'text',
          name: 'query.max_balance'
        }
      ]
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    openForm(name, data = {}, mode = '') {
      this.showingForm = name;

      this.$set(this, 'passData', {
        mode,
        ...data
      });
    },
    handleAction(fn) {
      fn(this);
    },
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;
      this.filter.query.merchant_id = localStorage.getItem('mrId');

      const Cards = await getCardSummary({
        ...this.filter,
        ...condition
      });
      if (!Cards.error) {
        this.result.total = Cards.total;
        this.result.page = Cards.page;
        this.$set(
          this.result,
          'list',
          Cards.items.map((item) => ({
            ...item,
            success_rate:
              Number(this.$num.multiply(item.success_rate, 100)).toFixed(2) +
              '%'
          }))
        );
      }

      this.isLoading = false;
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
